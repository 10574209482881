import { useState } from "react"
import { Sling as Hamburger } from 'hamburger-react'
import styled from "styled-components"


const Navbar = () => {
    const [menuState, setMenuState] = useState(false);
    const [pageTracker] = useState(window.location.pathname);

    return (
        <Header className="container">
            <nav className="wrapper nav">
                <a href="/">
                    <img className="nav__logo" src="/assets/logo-dark.svg" alt="Scytia" />
                </a>
                <ul className={menuState ? "nav__menu active" : "nav__menu"}>
                    <li><a href="/" className={pageTracker === '/' ? "nav__link active" : "nav__link"} >Start</a></li>
                    <li><a href="/poznaj-nas" className={pageTracker === '/poznaj-nas' ? "nav__link active" : "nav__link"} >Poznaj nas</a></li>
                    <li><a href="/mieszkania" className={pageTracker === '/mieszkania' ? "nav__link active" : "nav__link"} >Mieszkania</a></li>
                    {/* <li><a href="/galeria" className={pageTracker === '/galeria' ? "nav__link active" : "nav__link"} >Galeria</a></li> */}
                    <li><a href="/kontakt" className={pageTracker === '/kontakt' ? "nav__link active" : "nav__link"} >Kontakt</a></li>
                </ul>
                <div className="nav__toggle" onClick={() => setMenuState(!menuState)} >
                    <Hamburger/>
                </div>
            </nav>
        </Header>
    )
}

const Header = styled.header`
    position: relative;
    .nav {
        display: flex;
        justify-content: space-between;
        align-items: center;
        z-index: 9999;
        &__logo {
            width: 127px;
        }
        &__menu {
            position: absolute;
            left: 0;
            top: 100%;
            width: 100%;
            padding: 4rem 1rem;
            list-style: none;
            background-color: ${props => props.theme.white};;
            transform-origin: top;
            transform: scaleY(0);
            transition: transform .3s;
            z-index: 999;
        }
        &__menu.active {
            transform: scaleY(100%);
        }
        &__link {
            display: block;
            padding: 1rem;
            text-align: center;
            font-weight: 500;
            font-size: 14px;
            line-height: 158%;
            letter-spacing: 0.13em;
            opacity: .4;
            text-transform: uppercase;
            transition: opacity .3s;
            &:hover {
                opacity: 1;
            }
        }
        &__link.active {
            opacity: 1;
            pointer-events: none;
        }
    }


     /* Media queries */
    @media screen and (min-width: 998px) {
        .nav {
            &__menu {
                position: relative;
                display: flex;
                align-items: center;
                gap: 4rem;
                padding: 0;
                width: auto;
                transform: scaleY(100%);
            }
            &__link {
                padding: 0;
            }
            &__toggle {
                display: none;
            }
        }
    }
`

export default Navbar