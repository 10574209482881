import styled from 'styled-components'
import ImageGallery from 'react-image-gallery';
import contents from "../../contents.json";

const Carousel = ({ type }) => {

  const
    singleImg = [
      {
        original: "/assets/gallery/SingleRoom/scytia-single-room (1).png",
        thumbnail: "/assets/gallery/SingleRoom/thumbs/scytia-single-room-thumb (1).png"
      },
      {
        original: "/assets/gallery/SingleRoom/scytia-single-room (2).png",
        thumbnail: "/assets/gallery/SingleRoom/thumbs/scytia-single-room-thumb (2).png"
      },
      {
        original: "/assets/gallery/SingleRoom/scytia-single-room (3).png",
        thumbnail: "/assets/gallery/SingleRoom/thumbs/scytia-single-room-thumb (3).png"
      },
      {
        original: "/assets/gallery/SingleRoom/scytia-single-room (4).png",
        thumbnail: "/assets/gallery/SingleRoom/thumbs/scytia-single-room-thumb (4).png"
      },
      {
        original: "/assets/gallery/SingleRoom/scytia-single-room (5).png",
        thumbnail: "/assets/gallery/SingleRoom/thumbs/scytia-single-room-thumb (5).png"
      },
      {
        original: "/assets/gallery/SingleRoom/scytia-single-room (6).png",
        thumbnail: "/assets/gallery/SingleRoom/thumbs/scytia-single-room-thumb (6).png"
      },
      {
        original: "/assets/gallery/SingleRoom/scytia-single-room (7).png",
        thumbnail: "/assets/gallery/SingleRoom/thumbs/scytia-single-room-thumb (7).png"
      },
      {
        original: "/assets/gallery/SingleRoom/scytia-single-bathroom (1).JPG",
        thumbnail: "/assets/gallery/SingleRoom/thumbs/scytia-single-bathroom-thumb (1).png"
      },
      {
        original: "/assets/gallery/SingleRoom/scytia-single-bathroom (2).JPG",
        thumbnail: "/assets/gallery/SingleRoom/thumbs/scytia-single-bathroom-thumb (2).png"
      },
      {
        original: "/assets/gallery/SingleRoom/scytia-single-bathroom (3).JPG",
        thumbnail: "/assets/gallery/SingleRoom/thumbs/scytia-single-bathroom-thumb (3).png"
      }
    ],
    doubleImg = [
      {
        original: "/assets/gallery/DoubleRoom/scytia-double-room (1).png",
        thumbnail: "/assets/gallery/DoubleRoom/thumbs/scytia-double-room-thumb (1).png"
      },
      {
        original: "/assets/gallery/DoubleRoom/scytia-double-room (2).png",
        thumbnail: "/assets/gallery/DoubleRoom/thumbs/scytia-double-room-thumb (2).png"
      },
      {
        original: "/assets/gallery/DoubleRoom/scytia-double-room (3).png",
        thumbnail: "/assets/gallery/DoubleRoom/thumbs/scytia-double-room-thumb (3).png"
      },
      {
        original: "/assets/gallery/DoubleRoom/scytia-double-room (4).png",
        thumbnail: "/assets/gallery/DoubleRoom/thumbs/scytia-double-room-thumb (4).png"
      },
      {
        original: "/assets/gallery/DoubleRoom/scytia-double-room (5).png",
        thumbnail: "/assets/gallery/DoubleRoom/thumbs/scytia-double-room-thumb (5).png"
      },
      {
        original: "/assets/gallery/DoubleRoom/scytia-double-room (6).png",
        thumbnail: "/assets/gallery/DoubleRoom/thumbs/scytia-double-room-thumb (6).png"
      },
      {
        original: "/assets/gallery/DoubleRoom/scytia-double-room (7).png",
        thumbnail: "/assets/gallery/DoubleRoom/thumbs/scytia-double-room-thumb (7).png"
      },
      {
        original: "/assets/gallery/DoubleRoom/scytia-double-room (8).png",
        thumbnail: "/assets/gallery/DoubleRoom/thumbs/scytia-double-room-thumb (8).png"
      },
      {
        original: "/assets/gallery/DoubleRoom/scytia-double-room (9).png",
        thumbnail: "/assets/gallery/DoubleRoom/thumbs/scytia-double-room-thumb (9).png"
      },
      {
        original: "/assets/gallery/DoubleRoom/scytia-double-room (10).png",
        thumbnail: "/assets/gallery/DoubleRoom/thumbs/scytia-double-room-thumb (10).png"
      },
      {
        original: "/assets/gallery/DoubleRoom/scytia-double-room (11).png",
        thumbnail: "/assets/gallery/DoubleRoom/thumbs/scytia-double-room-thumb (11).png"
      },
      {
        original: "/assets/gallery/DoubleRoom/scytia-double-room (12).png",
        thumbnail: "/assets/gallery/DoubleRoom/thumbs/scytia-double-room-thumb (12).png"
      },
      {
        original: "/assets/gallery/DoubleRoom/scytia-double-room (13).png",
        thumbnail: "/assets/gallery/DoubleRoom/thumbs/scytia-double-room-thumb (13).png"
      },
      {
        original: "/assets/gallery/DoubleRoom/scytia-double-room (14).png",
        thumbnail: "/assets/gallery/DoubleRoom/thumbs/scytia-double-room-thumb (14).png"
      },
      {
        original: "/assets/gallery/DoubleRoom/scytia-double-room (15).png",
        thumbnail: "/assets/gallery/DoubleRoom/thumbs/scytia-double-room-thumb (15).png"
      },
      {
        original: "/assets/gallery/DoubleRoom/scytia-double-room (16).png",
        thumbnail: "/assets/gallery/DoubleRoom/thumbs/scytia-double-room-thumb (16).png"
      },
      {
        original: "/assets/gallery/DoubleRoom/scytia-double-room (17).png",
        thumbnail: "/assets/gallery/DoubleRoom/thumbs/scytia-double-room-thumb (17).png"
      },
      {
        original: "/assets/gallery/DoubleRoom/scytia-double-room (19).png",
        thumbnail: "/assets/gallery/DoubleRoom/thumbs/scytia-double-room-thumb (19).png"
      },
      {
        original: "/assets/gallery/DoubleRoom/scytia-double-room (20).png",
        thumbnail: "/assets/gallery/DoubleRoom/thumbs/scytia-double-room-thumb (20).png"
      },
      {
        original: "/assets/gallery/DoubleRoom/scytia-double-bathroom (1).JPG",
        thumbnail: "/assets/gallery/DoubleRoom/thumbs/scytia-double-bathroom-thumb (1).png"
      },
      {
        original: "/assets/gallery/DoubleRoom/scytia-double-bathroom (2).JPG",
        thumbnail: "/assets/gallery/DoubleRoom/thumbs/scytia-double-bathroom-thumb (2).png"
      },
      {
        original: "/assets/gallery/DoubleRoom/scytia-double-bathroom (3).JPG",
        thumbnail: "/assets/gallery/DoubleRoom/thumbs/scytia-double-bathroom-thumb (3).png"
      },
      {
        original: "/assets/gallery/DoubleRoom/scytia-double-bathroom (4).JPG",
        thumbnail: "/assets/gallery/DoubleRoom/thumbs/scytia-double-bathroom-thumb (4).png"
      }
    ];

  return (
    <Section className="container section">

      <div className="section__heading outerHeading">
        <h4 className='section__heading-subtext'>Mieszkanie</h4>
        <h1 className='section__heading-text'>
          {
            type === 'single' ? 'Jednopokojowe' : 'Dwupokojowe'
          }
        </h1>
        <br />
        <br />
      </div>

      <div className="wrapper sideToSide carousel">

        <div className="sideToSide__col1 carousel__left">
          <ImageGallery items={type === 'single' ? singleImg : doubleImg} />
        </div>

        <div className="sideToSide__col2 carousel__right">
          <div className="section__heading innerHeading">
            <h4 className='section__heading-subtext'>Mieszkanie</h4>
            <h1 className='section__heading-text'>
              {
                type === 'single' ? contents.flats.small.heading : contents.flats.large.heading
              }
            </h1>
          </div>

          <div className="carousel__content">
            {
              type === 'single' ?
                <p className='carousel__content-text'>
                  <br />
                  {contents.flats.small.text1}
                  <br /><br />
                  {contents.flats.small.text2}
                  <br /><br />
                  {contents.flats.small.text3}
                </p>
                :
                <p className='carousel__content-text'>
                  <br />
                  {contents.flats.large.text1}
                  <br /><br />
                  {contents.flats.large.text2}
                  <br /><br />
                  {contents.flats.large.text3}
                </p>
            }
          </div>

        </div>

      </div>
    </Section>

  )
}

const Section = styled.div`
  .outerHeading {
    text-align: center;
  }
  .innerHeading {
    display: none;
    h1 {
      font-size: 3.998rem;
    }
  }
  .carousel {
    align-items: flex-start;
    &__right {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
    }
    &__content-text {
      text-align: justify;
      width: 100%;
    }
  }
  .image-gallery{
    width: 100%;
  }
  .image-gallery-swipe, .image-gallery-slide-wrapper, .image-gallery-content, .image-gallery {
    border-radius: 8px;
    overflow: hidden;
  }
  .image-gallery-slides {
    width: 100%;
    height: 100%;
  }
  .image-gallery-slide {
    width: 100% !important;
    height: 530px;
    object-fit: cover;
  }
  .image-gallery-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .image-gallery-thumbnail-inner {
    border-radius: 8px;
    overflow: hidden;
    width: 100%;
    height: 80px;
  }
  .image-gallery-thumbnail-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .image-gallery-icon {
    &:hover {
      color: ${props => props.theme.mainColor} !important;
    }
  }
  .image-gallery-thumbnail {
    border-radius: 12px;
    cursor: pointer;
    &:hover {
      border-color: ${props => props.theme.mainColor};
    }
  }
  .image-gallery-thumbnail.active {
    border-color: ${props => props.theme.mainColor};
  }

  .image-gallery-content.fullscreen {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    height: 100vh;
    .image-gallery-slide {
      height: 100%;
    }
    .image-gallery-image {
      width: auto;
      height: auto;
    }
  }

  @media screen and (min-width: 998px) {
    .outerHeading {
      display: none;
    }
    .innerHeading {
      display: block;
    }
  }
`

export default Carousel