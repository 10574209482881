import { Helmet } from "react-helmet";
import styled from "styled-components"
import Header from "../components/Header"
import About from "../components/Start/About"
import Flats from "../components/Start/Flats"
// import Gallery from "../components/Start/Gallery"
import Contact from "../components/Contact"

const Start = () => {

  return (
    <Main className="container">
      <Helmet>
        <title>Scytia - strona głowna</title>
        <meta name="description" content="Some description." />
        <meta property="og:title" content="MyApp" />
      </Helmet>
      <Header details={true} />
      <About />
      <Flats />
      {/* <Gallery /> */}
      <Contact />
    </Main>
  )
}

const Main = styled.main`
  padding: 0;
  background: none;
`

export default Start