import styled from "styled-components"

const Cards = ({details}) => {

  return (
    <Section className="container section cards">

        <div className="wrapper cards__container">
            <div className="sideToSide cards__items">
                {
                    details?.map(item => (
                        <div key={item.id} className="card">
                            <i className={'bx ' + item.icon}></i>
                            <h4 className="card__heading">{item.title}</h4>
                            <p className="card__desc">{item.desc}</p>
                        </div>
                    ))
                }
            </div>
        </div>

    </Section>
  )
}

const Section = styled.section`
    background-color: ${props => props.theme.lightGray};
    position: relative;
    margin: 100px 0;
    &::before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        height: 100px;
        width: 80%;
        transform: translateY(-100%);
        background-color: ${props => props.theme.lightGray};
        clip-path: polygon(10% 0%, 100% 0, 100% 100%, 0% 100%);
    }
    &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        height: 100px;
        width: 80%;
        transform: translateY(100%);
        background-color: ${props => props.theme.lightGray};
        clip-path: polygon(0 0, 100% 0, 90% 100%, 0% 100%);
    }

    .cards {
        &__items {
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
        }
    }

    .card {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 22px;
        padding: 5rem 2rem;
        text-align: center;
        background: ${props => props.theme.white};
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
        border-radius: 8px;
        overflow: hidden;
        width: 100%;
        height: 330px;
        &__heading {
            text-transform: uppercase;
        }
        &__desc {
            max-width: 25ch;
        }
        .bx {
            font-size: 6.7rem;
            color: ${props => props.theme.mainColor};
        }
    }

    /* Media queries */
    @media screen and (min-width: 576px) {
        .about {
            &__left {
                background-image: url('/assets/about-start-dsk.png');
            }
       }
    }

    @media screen and (min-width: 998px) {
        &::before {
            width: 50%;
        }
        &::after {
            width: 50%;
        }

        .card {
            width: 22.5%;
        }
        
    }

`

export default Cards