import { useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ThemeProvider } from 'styled-components';
import { motion } from "framer-motion"
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import Topbar from "./components/Topbar";
import Start from './routes/Start.page';
import Contact from './routes/Contact.page';
import About from './routes/About.page';
import NotFound from './routes/NotFound.page';
import ThankYou from "./routes/ThankYou.page";
import Flats from "./routes/Flats.page";
import Flat from "./routes/Flat.page";
import Rodo from "./routes/Rodo";

const theme = {
  mainColor: '#c2b261',
  darkBlue: '#1B3D2F',
  darkGray: '#2E2E2E',
  lightGray: '#F3F3F3',
  white: '#FFFFFF',
};

function App() {
  const [pageTracker] = useState(window.location.pathname);



  return (
    <motion.div animate={{ opacity: 1 }} initial={{ opacity: 0 }} transition={{ duration: .3 }}>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          {
            pageTracker !== '/mieszkania' &&
            <>
              <Topbar />
              <Navbar />
            </>
          }
            <Routes>
              <Route element={<Start />} path="/" />
              <Route element={<About />} path="/poznaj-nas" />
              <Route element={<Contact />} path="/kontakt" />
              <Route element={<Flats />} path="/mieszkania" />
              <Route element={<Flat type={'single'} />} path="/mieszkania/jednopokojowe" />
              <Route element={<Flat type={'double'} />} path="/mieszkania/dwupokojowe" />
              <Route element={<Rodo />} path="/polityka-prywatnosci" />
              <Route element={<NotFound />} path="*" />
              <Route element={<ThankYou />} path="/kontakt/wiadomosc-wyslana" />
            </Routes>
          {
            pageTracker !== '/mieszkania' &&
            <>
              <Footer />
            </>
          }
        </BrowserRouter>
      </ThemeProvider>
    </motion.div>
  );
}

export default App;
