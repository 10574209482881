import React from 'react';
import styled from 'styled-components';
import contents from "../../contents.json";

const Flats = () => {
    return (
        <Section className="container section flats">
            <div className="wrapper flats__container">
                <div className="section__heading flatsHeading ">
                    <h2 className='section__heading-text'>{contents.start.section3.heading}</h2>
                    <p className='section__heading-extra'>
                        {contents.start.section3.subHeading}
                    </p>
                </div>
                <br />
                <br />
                <br />
                <div className="sideToSide">

                    <div className="sideToSide__col1 flats__left">
                        <div className="card">
                            <div className="image-wrapper card__img card__img--single">

                            </div>
                            <div className="card__content">
                                <h4 className="card__content-heading">{contents.start.section3.cardHeading1}</h4>
                                <p className="card__content-text">
                                    {contents.start.section3.cardText1}
                                </p>
                                <a href="/mieszkania/jednopokojowe" className="btn btn-icon">
                                    <i className='bx bxs-clinic'></i>
                                    Zobacz więcej
                                </a>
                            </div>
                        </div>
                    </div>

                    <div className="sideToSide__col2 flats__right">
                        <div className="card">
                            <div className="image-wrapper card__img card__img--double">

                            </div>
                            <div className="card__content">
                                <h4 className="card__content-heading">{contents.start.section3.cardHeading2}</h4>
                                <p className="card__content-text">
                                    {contents.start.section3.cardText2}
                                </p>
                                <a href="/mieszkania/dwupokojowe" className="btn btn-icon">
                                    <i className='bx bxs-clinic'></i>
                                    Zobacz więcej
                                </a>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </Section>
    )
}

const Section = styled.section`
    background-color: ${props => props.theme.lightGray};
    position: relative;
    margin: 100px 0;
    &::before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        height: 100px;
        width: 80%;
        transform: translateY(-100%);
        background-color: ${props => props.theme.lightGray};
        clip-path: polygon(10% 0%, 100% 0, 100% 100%, 0% 100%);
    }
    &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        height: 100px;
        width: 80%;
        transform: translateY(100%);
        background-color: ${props => props.theme.lightGray};
        clip-path: polygon(0 0, 100% 0, 90% 100%, 0% 100%);
    }

    .flatsHeading {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .flats {
        &__left  {
            align-items: center;
            justify-content: center;
        }
        &__right {
            align-items: center;
            justify-content: center;
        }
    }

    .card {
        background: ${props => props.theme.white};
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
        border-radius: 8px;
        overflow: hidden;
        max-width: 500px;
        &__img {
            width: 100%;
            min-height: 200px;
            &--single {
                background-image: url('/assets/one-room.png');
            }
            &--double {
                background-image: url('/assets/two-rooms.png');
            }
        }
        &__content {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            gap: 20px;
            padding: 2rem;
            text-align: justify;
            &-heading {
                font-weight: 700;
                line-height: 112%;
                text-transform: uppercase;
            }
            &-text {
                min-height: 80px;
            }
        }
    }


    /* Media queries */
    @media screen and (min-width: 576px) {
        .about {
            &__left {
                background-image: url('/assets/about-start-dsk.png');
            }
       }
    }

    @media screen and (min-width: 998px) {
        &::before {
            width: 50%;
        }
        &::after {
            width: 50%;
        }
        
    }

`

export default Flats