import React, { useState } from 'react';
import styled from 'styled-components';
import contents from "../contents.json";

const Footer = () => {
    const [footerYear] = useState(new Date().getFullYear());

    return (
        <Foot className="container" >
            <div className="wrapper footer">
                <div className="footer__top">

                    <a href="/" className='footer__logo'>
                        <img src="/assets/logo-light.svg" alt="Scytia" width="100%" />
                    </a>

                    <div className="footer__top-left">

                        <div>
                            <ul className="footer__list footer__list--contact">
                                <li className="footer__list-item">
                                    <p className='footer__heading'>Dane kontaktowe:</p>
                                    <span>Tel: {contents.contact.details.phone}</span>
                                    <br />
                                    <span>E: {contents.contact.details.email}</span>
                                </li>
                                <li className="footer__list-item">
                                    <span>
                                        {contents.contact.details.location}
                                    </span>
                                </li>
                            </ul>
                        </div>

                        <div>
                            <p className='footer__heading'>Menu</p>
                            <ul className="footer__list">
                                <li className="footer__list-item"><a href="/">Start</a></li>
                                <li className="footer__list-item"><a href="/poznaj-nas">Poznaj nas</a></li>
                                <li className="footer__list-item"><a href="/mieszkania">Mieszkania</a></li>
                                <li className="footer__list-item"><a href="/galeria">Galeria</a></li>
                                <li className="footer__list-item"><a href="/kontakt">Kontakt</a></li>
                            </ul>
                        </div>

                        <div>
                            <p className='footer__heading'>Inne</p>
                            <ul className="footer__list">
                                {/* <li className="footer__list-item"><a href="/mapa-witryny">Mapa witryny</a></li> */}
                                <li className="footer__list-item"><a href="/polityka-prywatnosci">Polityka <br /> Prywatności</a></li>
                                {/* <li className="footer__list-item"><a href="/regulamin">Regulamin</a></li> */}
                            </ul>
                        </div>

                    </div>

                    <div className="footer__top-right">
                        <div className="mapouter">
                            <div className="gmap_canvas">
                                <img className="gmaps" src="/assets/location-map.png" alt="" />
                            </div>
                        </div>
                    </div>

                </div>
                <div className="footer__bottom">
                    <p className="footer__bottom-text">SCYTIA © {footerYear} | Designed and created by Bartosz Solga</p>
                </div>
            </div>
        </Foot>
    )
}


const Foot = styled.footer`
    position: relative;
    background-color: var(--dark-blue);
    color: ${props => props.theme.white};
    margin-top: 100px;
    &::before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        height: 100px;
        width: 80%;
        transform: translateY(-100%);
        background-color: var(--dark-blue);
        clip-path: polygon(10% 0%, 100% 0, 100% 100%, 0% 100%);
    }

    .mapouter, .gmap_canvas, .gmap_iframe {
        width: 100%;
        height: 100%;
        border: none;
    }

    .gmaps {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: top;
    }


    .footer {
        &__logo {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            img {
                max-width: 500px;
            }
        }
        &__top {
            display: flex;
            flex-direction: column;
            align-content: center;
            gap: 50px;
            padding: 5rem 1rem;
            &-left {
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;
                align-items: flex-start;
                gap: 30px;
            }
            &-right {
                .mapouter {
                    width: 100%;
                    height: 250px;
                    border-radius: 8px;
                    overflow: hidden;
                    border: 6px solid ${props => props.theme.white};
                }
            }
        }

        &__heading {
            font-weight: 700;
            font-size: 16px;
            line-height: 127%;
        }

        &__list {
            width: 100%;
            margin-left: 2rem;
            list-style: circle;
            &-item {
                padding: .3rem;
                font-weight: 300;
                font-size: 14px;
                line-height: 23px;
                letter-spacing: 0.045em;
            }
            &--contact {
                list-style: none;
                margin: 0;
            }
        }

        &__bottom {
            padding-top: 1.9rem;
            font-size: 1.4rem;
            border-top: 1px solid ${props => props.theme.lightGray};
            &-text {
                width: 100%;
                text-align: center;
            }
        }
    }

    /* Media queries */
    @media screen and (min-width: 576px) {
        .footer {
            &__top {
                &-left {
                    flex-direction: row;
                }
            }
        }
    }


    @media screen and (min-width: 998px) {
        &::before {
            width: 50%;
        }
        
    }

    @media screen and (min-width: 1500px) {
        .footer {
            &__logo {
                width: 350px;
            }
            &__top {
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                &-left {
                    width: 40%;
                    flex-direction: row;
                }
                &-right {
                    display: flex;
                    justify-content: end;
                    width: 50%;
                    .mapouter {
                        max-width: 550px;
                        max-height: 250px;
                    }
                }
            }
        }
    }
`

export default Footer