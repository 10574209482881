import styled from 'styled-components'
import Carousel from '../components/Flats/Carousel'
import Cards from '../components/Cards'
import ContactForm from "../components/Contact"
import Extra from '../components/Flats/Extra'

const Single = ({type}) => {
  const details = [
    {
        id: 0,
        icon: 'bx-train',
        title: 'Komunikacja',
        desc: ''
    },
    {
        id: 1,
        icon: 'bxs-bed',
        title: 'Komfort',
        desc: ''
    },
    {
        id: 2,
        icon: 'bxs-leaf',
        title: 'Spokojna okolica',
        desc: ''
    },
    {
        id: 3,
        icon: 'bxs-hdd',
        title: 'Technologie',
        desc: ''
    },
  ]

  return (
    <Main className="container">
      <Carousel type={type}/>
      <Cards details={details} />
      <Extra />
      <ContactForm />
    </Main>
  )
}

const Main = styled.main`
    padding: 0;
    background: none;
    .card {
      max-height: 210px;
    }
`

export default Single