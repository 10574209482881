import styled from 'styled-components'
import contents from "../../contents.json"

const About = () => {
    return (
        <Section className="container section">
            <div className="wrapper sideToSide about">

                <div className="image-wrapper sideToSide__col1 about__left">

                </div>

                <div className="sideToSide__col2 about__right">

                    <div className="section__heading">
                        <img src="/assets/about-heading-shadow.svg" alt='' className='section__heading-shadow' />
                        <h2 className='section__heading-text'>{contents.about.subHeading1}</h2>
                    </div>

                    <div className="about__content">
                        <p className='about__content-text'>
                            {contents.about.text1}
                        </p>
                    </div>

                </div>
            </div>
        </Section>
    )
}

const Section = styled.section`
    .about {
        &__left {
            background-image: url('/assets/about-inwestment-mb.png');
            border-radius: 8px;
        }
        &__right {
            display: flex;
            flex-direction: column;
            gap: 49px;
            .section__heading-shadow {
                width: 70%;
            }
        }
        &__content {
            &-heading{
                display: flex;
                align-items: center;
                gap: 10px;
                font-weight: 600;
                line-height: 165%;
                letter-spacing: 0.045em;
                .bx {
                    font-size: 3.4rem;
                    color: ${props => props.theme.mainColor};
                }
            }
            &-text {
                text-align: justify;
            }
        }
    }

    /* Media queries */
    @media screen and (min-width: 576px) {
        .about {
            flex-direction: row-reverse;
            &__left {
                background-image: url('/assets/about-inwestment-dsk.png');
            }
    }

    }
`

export default About