import styled from "styled-components";
import contents from "../contents.json";

const Topbar = () => {
  return (
    <Bar className="container">
      <div className="wrapper topbar">
        <p className="topbar__left">
          <span className="topbar__contact">
            <i className='bx bxs-envelope'></i>
            {contents.contact.details.email}
          </span>
        </p>
        <p className="topbar__right">
          {/* <a href="/FAQ">
            FAQ
          </a> */}
          <span className="topbar__contact">
            <i className='bx bxs-phone' ></i>
            {contents.contact.details.phone}
          </span>
        </p>
      </div>
    </Bar>
  )
}

const Bar = styled.div`
  display: none;
  font-size: 12px;
  line-height: 18px;
  border-bottom: 1px solid ${props => props.theme.lightGray};
  .topbar {
    display: flex;
    align-content: center;
    justify-content: space-between;
    font-size: 1.4rem;
    &__right, &__left {
      display: flex;
      align-items: center;
      font-weight: 500;
      gap: 10px;
      text-transform: uppercase;
      .bx {
        font-size: 1.6rem;
      }
    }
    &__contact {
      display: flex;
      gap: 10px;
      align-items: center;
      .bx {
        font-size: 2rem;
      }
    }
  }


   /* Media queries */
  @media screen and (min-width: 768px) {
    display: flex;
  }

`

export default Topbar