import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import contents from "../contents.json";

const Header = ({ details, title }) => {
    return (
        <Section className="section">
            <header className="container image-wrapper header">
                {
                    details ?
                        <>
                            <div className="wrapper header__content">
                                <div className="header__text">
                                    <h1 className="header__text-h1" >{contents.start.section1.heading}</h1>
                                    <h2 className="header__text-h2" >{contents.start.section1.subHeading}</h2>
                                </div>
                                <div className="header__btns">
                                    <a href="/mieszkania" className="btn header__btns-btn">
                                        <i className='bx bx-radio-circle bx-burst'></i>
                                        &nbsp;
                                        {contents.start.section1.firstBtn}
                                    </a>
                                    <a href="/poznaj-nas" className="btn-link header__btns-btn">{contents.start.section1.secondBtn}</a>
                                </div>
                            </div>
                        </>
                        :
                        <>
                            <h1 className='header__title'>{title}</h1>
                        </>
                }
                <div className="header__dimmer"></div>
            </header>
        </Section>
    )
}

Header.propTypes = {
    details: PropTypes.bool,
    title: PropTypes.string
};

Header.defaultProps = {
    details: false,
    title: ''
};

const Section = styled.section`
    padding: 0;
    .header {
        position: relative;
        width: 100%;
        min-height: 500px;
        padding: 12rem 1rem;
        background-image: url('/assets/header-mobile.png');
        color: ${props => props.theme.white};
        &__title {
            position: relative;
            z-index: 1;
        }
        &::after {
            content: "";
            position: absolute;
            bottom: -1px;
            left: 0;
            height: 100px;
            width: 80%;
            background-color: ${props => props.theme.white};
            clip-path: polygon(0 0, 90% 0%, 100% 100%, 0% 100%);
        }
        &__content {
            display: flex;
            flex-direction: column;
            gap: 50px;
            text-align: center;
            z-index: 1;
        }
        &__text {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 10px;
            &-h1 {
                text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.45);
            }
            &-h2 {
                font-weight: 300;
                line-height: 165%;
                letter-spacing: 0.045em;
                font-size: initial;
                text-transform: none;
            }
        }
        &__btns {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            &-btn {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 5px;
                    .bx {
                        font-size: 3rem;
                    }
                }
        }
        &__dimmer{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(0deg, rgba(0, 0, 0, 0.51), rgba(0, 0, 0, 0.71));
            transform: matrix(-1, 0, 0, 1, 0, 0);
            z-index: 0;
        }
    }

    /* Media queries */
    @media screen and (min-width: 576px) {
        .header {
            min-height: 700px;
            padding: 0 5rem;
            &__text {
                &-h1 {
                    max-width: 23ch;
                }
                &-h2 {
                    max-width: 45ch;
                }  
            }
            &__btns {
                flex-direction: row;
                &-btn {
                    font-size: 1.6rem;
                }
            }
        }
    }


    @media screen and (min-width: 998px) {
        .header {
            min-height: 807px;
            background-image: url('/assets/header-desktop.png');
            &::after {
                width: 50%;
            }
            &__content {
                text-align: left;
            }
            &__text {
                align-items: flex-start;
                &-h2 {
                font-size: 2rem;
                }  
            }
            &__btns {
                justify-content: flex-start;
                &-btn {
                font-size: 1.8rem;
                }
            }
            &__dimmer {
                background: linear-gradient(270deg, rgba(0, 0, 0, 0.91) -0.59%, rgba(0, 0, 0, 0) 100%);
            }
            }
    }


`

export default Header