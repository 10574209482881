import { motion } from 'framer-motion'
import styled from 'styled-components'

const Flats = () => {
  return (
    <Main> 
        <motion.div className="image-wrapper single" animate={{x: 0}} initial={{x: -800}} transition={{duration: 1}}>
          <div className="dimmer"></div>
          <div className="content">
            <div className="content__box">
              <h2>Mieszkanie <br /> Jednopokojowe</h2>
              <a href='/mieszkania/jednopokojowe' className="btn btn-icon">Zobacz więcej</a>
            </div>
          </div>
        </motion.div>
        <motion.div className="image-wrapper double" animate={{x: 0}} initial={{x: 800}} transition={{duration: 1}} >
          <div className="dimmer"></div>
          <div className="content">
            <div className="content__box">
              <h2>Mieszkanie <br /> Dwupokojowe</h2>
              <a href='/mieszkania/dwupokojowe' className="btn btn-icon">Zobacz więcej</a>
            </div>
          </div>
        </motion.div>
    </Main>
  )
}

const Main = styled.main`
    position: relative;
    padding: 0;
    background: none;
    overflow: hidden;
    width: 100vw;
    height: 100vh;
    background-color: #000;
    .single, .double {
      position: absolute;
      width: 100%;
      height: 50vh;
      filter: grayscale(1);
      &:hover {
        filter: grayscale(0);
        .dimmer {
          pointer-events: none;
          opacity: 0;
        }
        .content {
          &__box {
            background-color: #000000BB;
          }
        }
      }
    }
    .single {
      top: 0;
      left: 0;
      background-image: url('/assets/single-mb.png');
    }
    .double {
      bottom: 0;
      right: 0;
      background-image: url('/assets/double-mb.png');
    }

    .dimmer {
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: #000000BB;
      transition: opacity .3s;
      z-index: 1;
    }
    
    .content {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      text-align: center;
      color: #ffff;
      z-index: 0;
      &__box {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 20px;
        background-color: transparent;
        padding: 5rem;
        border-radius: 8px;
        transition: background-color .3s;
      }
    }
    
    @media screen and (min-width: 850px) {
      .single, .double {
        width: 56%;
        height: 100vh;
      }
      .single {
        background-image: url('/assets/single-dsk.png');
        clip-path: polygon(0 0, 100% 0%, 80% 100%, 0% 100%);
      }
      .double {
        top: 0;
        clip-path: polygon(20% 0%, 100% 0%, 100% 100%, 0% 100%);
        background-image: url('/assets/double-dsk.png');
      }
    }

`

export default Flats