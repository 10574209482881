import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from 'react-router-dom';
import Spinner from "./Spinner";
import contents from "../contents.json"

const Form = () => {
    const
        navigate = useNavigate(),
        { register, handleSubmit, formState: { errors } } = useForm(),
        [loader, setLoader] = useState(false),
        [error, setError] = useState("");

    function checkPhone(e) {
        const re = /^[0-9\b]+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            return true;
        }
    }

    const onSubmit = async (data) => {
        try {
            setLoader(true);
            await fetch('/api/send-mail', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            });

            navigate("/kontakt/wiadomosc-wyslana");
        } catch (error) {
            setError("Podczas wysyłania wiadomości wystąpił błąd.")
        }
    };

    return (
        <form className="form" onSubmit={handleSubmit(onSubmit)}>
            {/* UPPER ROW */}
            <div className="form__row">
                <div className="form__col">
                    <div className="form__group">
                        <input
                            className='form__input'
                            type="text"
                            name='name'
                            placeholder='Imię'
                            {...register("name", { required: true })}
                            aria-invalid={errors.name ? "true" : "false"}
                        />
                        {errors.name?.type === 'required' &&
                            <div className="form__error">
                                <p role="alert">* To pole należy wypełnić</p>
                            </div>
                        }
                    </div>
                </div>
                <div className="form__col">
                    <div className="form__group">
                        <input
                            className='form__input'
                            type="text"
                            name='surname'
                            placeholder='Nazwisko'
                            {...register("surname", { required: true })}
                            aria-invalid={errors.surname ? "true" : "false"}
                        />
                        {errors.surname?.type === 'required' &&
                            <div className="form__error">
                                <p role="alert">* To pole należy wypełnić</p>
                            </div>
                        }
                    </div>
                </div>
            </div>
            {/* LOWER ROW */}
            <div className="form__row">
                <div className="form__col">
                    <div className="form__group">
                        <input
                            className='form__input'
                            type="email"
                            name='email'
                            placeholder='Email'
                            {...register("email", { required: "Email jest wymagany", })}
                            aria-invalid={errors.email ? "true" : "false"}
                        />
                        {errors.email?.type === 'required' &&
                            <div className="form__error">
                                <p role="alert">* {errors.email?.message}</p>
                            </div>
                        }
                    </div>
                </div>
                <div className="form__col">
                    <div className="form__group">
                        <input
                            className='form__input'
                            type="number"
                            name='phone'
                            placeholder='Telefon'
                            onInput={checkPhone}
                            {...register("phone", { required: true })}
                            aria-invalid={errors.phone ? "true" : "false"}
                        />
                        {errors.phone?.type === 'required' &&
                            <div className="form__error">
                                <p role="alert">* Telefon jest wymagany</p>
                            </div>
                        }
                    </div>
                </div>
            </div>
            {/* Textarea */}
            <div className="form__row">
                <div className="form__col">
                    <div className="form__group">
                        <textarea
                            className='form__input form__input--textarea'
                            name="body"
                            id="body"
                            placeholder='Wiadomość'
                            {...register("body", { required: true, })}
                            aria-invalid={errors.body ? "true" : "false"}
                        ></textarea>
                        {errors.body?.type === 'required' &&
                            <div className="form__error">
                                <p role="alert">* To pole należy wypełnić</p>
                            </div>
                        }
                    </div>
                </div>
            </div>
            {/* RODO */}
            <div className="">
                <div className="form__col">
                    <div className="form__group form__group--rodo">
                        <input
                            className='form__input form__input--checkbox'
                            type="checkbox"
                            name='rodo'
                            {...register("rodo", { required: true, })}
                            aria-invalid={errors.rodo ? "true" : "false"}
                        />
                    </div>
                    <p className='form__agreement'>
                        {contents.contact.details.rodo} <a className="link" href="/polityka-prywatnosci"> - Polityka Prywatności</a>
                    </p>
                </div>
                {errors.rodo?.type === 'required' &&
                    <div className="form__error">
                        <p role="alert">* Aby przesłać widaomość musisz zaznaczyć zgodę</p>
                    </div>
                }
            </div>
            {/* RODO */}
            <div className="form__row">
                <div className="form__col">
                    <div className="form__group">
                        <button type='submit' className='btn btn-icon form__submit'>
                            {
                                loader ?
                                    <>
                                        <Spinner />
                                    </>
                                    :
                                    <>
                                        Wyślij
                                        <i className='bx bx-send'></i>
                                    </>
                            }
                        </button>
                    </div>
                </div>
            </div>
            <div className="form__error">
                <p role="alert">{error}</p>
            </div>
        </form>
    )
}

export default Form