import styled from 'styled-components'

const NotFound = () => {

  return (
    <Main className="container"> 
      <section className='container section notFound'>
        <div className="wrapper notFound__container">

          <img src='/assets/404.svg' alt='404' className='notFound__errCode' />

          <div className="notFound__content">
            <h1 className="notFound__content-heading">
              Wyszukiwana przez Ciebie strona nie istnieje :( 
            </h1>
            <p className="notFound__content-text">
              Na szczęście pomyśleliśmy o naszych zagubionych użytkownikach, dlatego poniżej zostawiliśmy dla Ciebie przycisk ratunkowy!
            </p>
          </div>

          <a href="/" className="btn btn-icon">
            Wróć na stronę główną
            <i className='bx bxs-right-arrow-square'></i>
          </a>

        </div>
      </section>
    </Main>
  )
}

const Main = styled.main`
  padding: 0;
  background: none;
  .notFound {
    position: relative;
    margin-top: 100px;
    background-color: ${props => props.theme.lightGray};
    &::before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        height: 100px;
        width: 80%;
        transform: translateY(-100%);
        background-color: ${props => props.theme.lightGray};
        clip-path: polygon(10% 0%, 100% 0, 100% 100%, 0% 100%);
    }
    &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        height: 100px;
        width: 80%;
        transform: translateY(100%);
        background-color: ${props => props.theme.lightGray};
        clip-path: polygon(0 0, 100% 0, 90% 100%, 0% 100%);
    }
    &__container {
      padding: 10rem 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      gap: 40px;
      max-width: 800px;
    }
    &__content {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      gap: 10px;
      &-heading {
        font-weight: 500;
        font-size: 2.5rem;
        line-height: 112%;
        text-transform: uppercase;
        max-width: 30ch;
        color: ${props => props.theme.mainColor};
      }
      &-text {
        max-width: 60ch;
        font-size: 1.4rem;
      }
    }
    &__errCode {
      font-size: 20rem;
      font-weight: 700;
      color: ${props => props.theme.white};
    }
  }

  @media screen and (min-width: 998px) {
    .notFound {
      &::before {
            width: 50%;
        }
        &::after {
            width: 60%;
        }
    }    
        
  }

`

export default NotFound