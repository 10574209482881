import styled from 'styled-components';
import Form from './Form';
import contents from "../contents.json";

const Contact = () => {

    return (
        <Section className="container section">
            <div className="wrapper sideToSide contact">

                <div className="sideToSide__col1 contact__left">

                    <div className="section__heading">
                        <h4 className='section__heading-subtext'>{contents.contact.upHeading}</h4>
                        <h2 className='section__heading-text'>{contents.contact.heading}</h2>
                    </div>

                    <div className="contact__details">
                        <div className="contact__details-left">
                            <div>
                                <ul className="details__list details__list--contact">
                                    <li className="details__list-item">
                                        <p className='details__heading'>Dane kontaktowe:</p>
                                        <span>Tel: {contents.contact.details.phone}</span>
                                        <br />
                                        <span>Email: {contents.contact.details.email}</span>
                                    </li>
                                    <li className="details__list-item">
                                        <p className='details__heading'>Lokalizacja:</p>
                                        <span>
                                            {contents.contact.details.location}
                                        </span>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="contact__details-right">
                            <div>
                                <ul className="details__list details__list--contact">
                                    <li className="details__list-item">
                                        <p className='details__heading'>Godziny pracy:</p>
                                        <span>{contents.contact.details.hours}</span>
                                    </li>
                                    {/* <li className="details__list-item socials">
                                        <p className='details__heading'>Śledź nas:</p>
                                        <div>
                                            <a href=""><i className='bx bxl-instagram-alt' ></i></a>
                                            <a href=""><i className='bx bxl-facebook-square' ></i></a>
                                        </div>
                                    </li> */}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="sideToSide__col2 contact__right">
                    <Form />
                </div>

            </div>
        </Section>
    )
}

const Section = styled.section`
    padding-top: 0; 
    padding-bottom: 10rem;
    .contact {
        &__left {
            display: flex;
            flex-direction: column;        
            align-items: center;
        }
        &__details {
            display: flex;
            flex-direction: column;
            width: 100%;
            &-left, &-right {
                display: flex;
                justify-content: center;
                width: 100%;
                text-align: center;
            }
        }
    }

    .details {
        &__heading {
            margin-bottom: 5px;
            font-weight: 700;
            font-size: 16px;
            line-height: 127%;
            color: initial;
        }
        &__list {
            width: 100%;
            height: 100%;
            margin-left: 2rem;
            list-style: none;
            &-item {
                padding: 2rem 0;
                font-weight: 300;
                font-size: 14px;
                line-height: 23px;
                letter-spacing: 0.045em;
                color: #868686;
            }
            &--contact {
                list-style: none;
                margin: 0;
            }
        }
    }

    .socials {
        .bx {
            font-size: 3rem;
        }
    }

    .form {
        display: flex;
        flex-direction: column;
        gap: 20px;
        &__row {
            display: flex;
            flex-direction: column;
            width: 100%;
            gap: 20px;
        }
        &__col {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            gap: 20px;
            width: 100%;
        }
        &__group {
            width: 100%;
            &--rodo {
                width: auto;
            }
        }
        &__input {
            padding: 10px 20px;
            width: 100%;
            border: 1px solid #DBDBDB;
            font-family: inherit;
            font-weight: 300;
            font-size: 14px;
            line-height: 165%;
            letter-spacing: 0.14em;
            &::placeholder {
                color: #868686;
            }
            &--textarea {
                max-width: 100%;
                min-width: 100%;
                max-height: 200px;
                min-height: 200px;
                resize: none;
            }
            &--checkbox {
                width: 25px;
                height: 25px;
                cursor: pointer;
                margin-top: .5rem;
                border: 1px solid #DBDBDB;
            }
        }
        &__agreement {
            text-align: justify;
            font-weight: 300;
            font-size: 1.2rem;
            line-height: 165%;
            letter-spacing: 0.045em;
            color: #868686;
        }
        &__submit {
            width: 100%;
        }
        &__error {
            display: flex;
            align-items: center;
            gap: 10px;
            color: #8f1311;
            .bx {
                font-size: 2rem;
            }
        }
    }
    


    /* Media queries */
    @media screen and (min-width: 998px) {
        padding-top: 10rem; 
        .contact {
            align-items: flex-start;
            justify-content: flex-start;
            &__left {
                align-items: flex-start;
                justify-content: flex-start;
            }
            &__details {
                flex-direction: row;
                &-left, &-right {
                    justify-content: flex-start;
                    text-align: left;
                }
            }
        }

        .form {
            &__row {
                flex-direction: row;
            }
            &__col {
                flex-direction: row;
                width: 100%;
            }
            &__submit {
                width: auto;
            }
    }
    
    }
`

export default Contact