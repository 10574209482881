import styled from 'styled-components'

const Surroundings = () => {
    return (
        <Section className="container section">
            <div className="wrapper sideToSide about">

                <div className="image-wrapper sideToSide__col1 about__left">

                </div>

                <div className="sideToSide__col2 about__right">

                    <div className="section__heading">
                        <img src="/assets/surr-heading-shadow.svg" alt='' className='section__heading-shadow' />
                        <h2 className='section__heading-text'>Okolica Mieszkań</h2>
                    </div>

                    <div className="about__content">
                        <p className='about__content-text'>
                            Inwestycja zlokalizowana jest przy granicy z miastem Świątniki Górne. Okolica mieszkań to głównie domy jednorodzinne. W pobliżu budynku   znajduje się duża ilość terenów zielonych, co z pewnością sprzyja lepszej jakości powietrza, jak i wygłusza hałasy z pobliskich ulic. Zaledwie 200 metrów od budynku znajduje się przystanek autobusowy - Wrząsowice Zielona, z którego odjeżdża autobus 215 i jedzie bezpośrednio do Krakowa. W odległości 750 m zlokalizowany jest najbliższy sklep spożywczy Prima Max. Oprócz tego w Świątnikach Górnych jest jeszcze kilka sklepów, w tym Biedronka i Delikatesy Centrum.
                            <br /><br />
                            Tuż przy rondzie w Świątnikach Górnych usytuowana jest publiczna Szkoła Podstawowa im. Adama Mickiewicza w Świątnikach Górnych <a href="https://www.spswiatnikigorne.eu/">https://www.spswiatnikigorne.eu/</a> , a także Szkoła Muzyczna I stopnia w Świątnikach Górnych <a href="https://www.sm-swiatniki.pl/">https://www.sm-swiatniki.pl/</a>. 500 m dalej mieści się Przedszkole nr 2 w Świątnikach Górnych <a href="https://www.przedszkolenr2.swiatniki-gorne.pl/">https://www.przedszkolenr2.swiatniki-gorne.pl/</a> , a 800 m dalej Zespół Szkół w Świątnikach Górnych <a href="https://www.zsswiatniki.pl/">https://www.zsswiatniki.pl/</a>.
                        </p>
                    </div>

                </div>
            </div>
        </Section>
    )
}

const Section = styled.section`
    .about {
        &__left {
            background-image: url("/assets/gallery-start-dsk.png");
            border-radius: 8px;
        }
        &__right {
            display: flex;
            flex-direction: column;
            gap: 49px;
            .section__heading-shadow {
                width: 70%;
            }
        }
        &__content {
            &-heading{
                display: flex;
                align-items: center;
                gap: 10px;
                font-weight: 600;
                line-height: 165%;
                letter-spacing: 0.045em;
                .bx {
                    font-size: 3.4rem;
                    color: ${props => props.theme.mainColor};
                }
            }
            &-text {
                text-align: justify;
                a:hover {
                    text-decoration: underline;
                }
            }
        }
    }

    /* Media queries */
    @media screen and (min-width: 576px) {
        .about {
            flex-direction: row;
            &__left {
                background-image: url("/assets/gallery-start-dsk.png");
            }
    }

    }
`

export default Surroundings