import styled from "styled-components"
import ContactForm from "../components/Contact"
import Header from "../components/Header"

const Contact = () => {
    return (
        <Main className="container">
            <Header details={false} title="Kontakt" />
            <ContactForm />
        </Main>
      )
}
    
const Main = styled.main`
    padding: 0;
    background: none;
    .header {
        min-height: 400px;
        background-image: none;
        background-color: var(--dark-blue);
    }

    @media screen and (min-width: 998px) {
        .header {
            min-height: 550px;
        }
    }
`

export default Contact