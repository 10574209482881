import React from 'react'
import styled from "styled-components"

const Rodo = () => {
    return (
        <Main className="container">
            <section class="container section wrapper">
                <div>
                    <h1>Polityka prywatności opisuje zasady przetwarzania przez nas informacji na Twój temat, w tym danych osobowych.</h1>
                    <br />
                    <h2>1. Informacje ogólne</h2>
                    <br />
                    <p>
                        Niniejsza polityka dotyczy Serwisu www, funkcjonującego pod adresem url: scytia.pl
                        <br />
                        Operatorem serwisu oraz Administratorem danych osobowych jest: SCYTIA SPÓŁKA CYWILNA ANNA KOWAL WOJCIECH KOWAL ul. Węgielnik 7, 32-040 Świątniki Górne
                        <br />
                        Adres kontaktowy poczty elektronicznej operatora: kontakt@scytia.pl
                        <br />
                        Operator jest Administratorem Twoich danych osobowych w odniesieniu do danych podanych dobr /owolnie w Serwisie.
                        <br />
                        Serwis wykorzystuje dane osobowe w następujących celach:
                        <br />&nbsp; - Prowadzenie newslettera
                        <br />&nbsp; - Obsługa zapytań przez formularz
                        <br />&nbsp; - Realizacja zamówionych usług
                        <br />&nbsp; - Prezentacja oferty lub informacji
                        <br />Serwis realizuje funkcje pozyskiwania informacji o użytkownikach i ich zachowaniu w następujący sposób:
                        <br />- Poprzez dobr /owolnie wprowadzone w formularzach dane, które zostają wprowadzone do systemów Operatora.
                        <br />
                    </p>
                    <br />
                    <h2>2. Wybr /ane metody ochrony danych stosowane przez Operatora</h2>
                    <br />
                    <p>Miejsca logowania i wprowadzania danych osobowych są chronione w warstwie transmisji (certyfikat SSL). Dzięki temu dane osobowe i dane logowania, wprowadzone na stronie, zostają zaszyfrowane w komputerze użytkownika i mogą być odczytane jedynie na docelowym serwerze.
                    </p>
                    <br />
                    <h2>3. Hosting</h2>
                    <br />
                    <p>Serwis jest hostowany (technicznie utrzymywany) na serwerach operatora: nazwa.pl
                        <br />
                        Firma hostingowa w celu zapewnienia niezawodności technicznej prowadzi logi na poziomie serwera. Zapisowi mogą podlegać:
                        <br />&nbsp; - zasoby określone identyfikatorem URL (adresy żądanych zasobów – stron, plików),
                        <br />&nbsp; - czas nadejścia zapytania,
                        <br />&nbsp; - czas wysłania odpowiedzi,
                        <br />&nbsp; - nazwę stacji klienta – identyfikacja realizowana przez protokół HTTP,
                        <br />&nbsp; - informacje o błędach jakie nastąpiły przy realizacji transakcji HTTP,
                        <br />&nbsp; - adres URL strony poprzednio odwiedzanej przez użytkownika (referer link) – w przypadku gdy przejście do Serwisu nastąpiło przez odnośnik,
                        <br />&nbsp; - informacje o przeglądarce użytkownika,
                        <br />&nbsp; - informacje o adresie IP,
                        <br />&nbsp; - informacje diagnostyczne związane z procesem samodzielnego zamawiania usług poprzez rejestratory na stronie,
                        <br />&nbsp; - informacje związane z obsługą poczty elektronicznej kierowanej do Operatora oraz wysyłanej przez Operatora.
                    </p>
                    <br />
                    <h2>4. Twoje prawa i dodatkowe informacje o sposobie wykorzystania danych</h2>
                    <br />
                    <p>W niektórych sytuacjach Administrator ma prawo przekazywać Twoje dane osobowe innym odbiorcom, jeśli będzie to niezbędne do wykonania zawartej z Tobą umowy lub do zrealizowania obowiązków ciążących na Administratorze. Dotyczy to takich grup odbiorców:
                        <br />upoważnieni pracownicy i współpracownicy, którzy korzystają z danych w celu realizacji celu działania strony
                        <br />Twoje dane osobowe przetwarzane przez Administratora nie dłużej, niż jest to konieczne do wykonania związanych z nimi czynności określonych osobnymi przepisami (np. o prowadzeniu rachunkowości). W odniesieniu do danych marketingowych dane nie będą przetwarzane dłużej niż przez 3 lata.
                        <br />Przysługuje Ci prawo żądania od Administratora:
                        <br />&nbsp; - dostępu do danych osobowych Ciebie dotyczących,
                        <br />&nbsp; - ich sprostowania,
                        <br />&nbsp; - usunięcia,
                        <br />&nbsp; - ograniczenia przetwarzania,
                        <br />&nbsp; - oraz przenoszenia danych.
                        <br />
                        Przysługuje Ci prawo do złożenia sprzeciwu w zakresie przetwarzania wskazanego w pkt 3.2 wobec przetwarzania danych osobowych w celu wykonania prawnie uzasadnionych interesów realizowanych przez Administratora, w tym profilowania, przy czym prawo sprzeciwu nie będzie mogło być wykonane w przypadku istnienia ważnych prawnie uzasadnionych podstaw do przetwarzania, nadrzędnych wobec Ciebie interesów, praw i wolności, w szczególności ustalenia, dochodzenia lub obr /ony roszczeń.

                        <br />Na działania Administratora przysługuje skarga do Prezesa Urzędu Ochrony Danych Osobowych, ul. Stawki 2, 00-193 Warszawa.
                        <br />Podanie danych osobowych jest dobr /owolne, lecz niezbędne do obsługi Serwisu.
                        <br />W stosunku do Ciebie mogą być podejmowane czynności polegające na zautomatyzowanym podejmowaniu decyzji, w tym profilowaniu w celu świadczenia usług w ramach zawartej umowy oraz w celu prowadzenia przez Administratora marketingu bezpośredniego.
                        <br />Dane osobowe nie są przekazywane od krajów trzecich w rozumieniu przepisów o ochronie danych osobowych. Oznacza to, że nie przesyłamy ich poza teren Unii Europejskiej.
                    </p>
                    <br />
                    <h2>5. Informacje w formularzach</h2>
                    <br />
                    <p>Serwis zbiera informacje podane dobr /owolnie przez użytkownika, w tym dane osobowe, o ile zostaną one podane.
                        <br />Serwis może zapisać informacje o parametrach połączenia (oznaczenie czasu, adres IP).<br />Serwis, w niektórych wypadkach, może zapisać informację ułatwiającą powiązanie danych w formularzu z adresem e-mail użytkownika wypełniającego formularz. W takim wypadku adres e-mail użytkownika pojawia się wewnątrz adresu url strony zawierającej formularz.<
                            br />Dane podane w formularzu są przetwarzane w celu wynikającym z funkcji konkretnego formularza, np. w celu dokonania procesu obsługi zgłoszenia serwisowego lub kontaktu handlowego, rejestracji usług itp. Każdorazowo kontekst i opis formularza w czytelny sposób informuje, do czego on służy.
                        <br />
                    </p>
                </div>
            </section>
        </Main>
    )
}

const Main = styled.main`
                                                                                                                                                                                                            padding: 0;
                                                                                                                                                                                                            background: none;
                                                                                                                                                                                                            `;

export default Rodo