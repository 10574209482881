import styled from "styled-components"
import Header from "../components/Header"
import Inwestment from "../components/About/Inwestment"
import Safety from "../components/About/Safety"
import Cards from "../components/Cards"
import Surroundings from "../components/About/Surroundings"
import contents from "../contents.json"

const About = () => {

    return (
        <Main className="container">
            <Header details={false} title="Poznaj nas" />
            <Inwestment />
            <Safety />
            <Cards details={contents.about.cards} />
            <Surroundings />
        </Main>
    )
}

const Main = styled.main`
    padding: 0;
    background: none;
    .header {
        min-height: 400px;
        background-image: url('/assets/about-header-mb.png');
    }

    @media screen and (min-width: 998px) {
        .header {
            min-height: 550px;
            background-image: url('/assets/about-header-dsk.png');
        }
    }
`

export default About